import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { withPrefix } from 'gatsby'
import Helmet from 'react-helmet'

import FullPageSection from '../components/FullPageSection'
import HeroTitle from '../components/HeroTitle'
import Footer from '../components/Footer'
import Header from '../components/HeaderEnglishOnly'

import bgImage from '../img/tlc-issues.jpg'
import flagImage from '../img/flagImage.png'

const PublicSafetyPage = (props) => (
  <main>
    <Helmet>
      <script
        src={withPrefix('/scripts/toc.js')}
        type="text/javascript"
        defer
      />
    </Helmet>
    <Header className="bg-white" />
    <h1 className="tmp-policy-title">
      A New Vision of Public Safety for New York City
    </h1>
    <p className="tmp-policy-pdf">
      <a href="/files/public-safety.pdf">PDF version of Policy Page</a>
    </p>
    <img src={flagImage} className="flagImage" height="auto" />

    <div className="tmp-public-safety">
      <div className="tmp-public-safety-top-toc"></div>

      <div className="tmp-public-safety-grid">
        <div className="tmp-public-safety-content">
          <section id="quotes">
            <h2>What Folks Are Saying</h2>
            <section id="wfp">
              <p>
                "The New York Working Families Party is proud to support Tiffany
                Caban's visionary policy platform on public safety for the city
                of New York,”{' '}
                <strong>
                  New York Working Families Party State Director Sochie Nnaemeka
                </strong>
                . “For far too long, New York has relied upon a public safety
                framework that centers punitive practices--ones that ultimately
                undermine, not build, community stability. Tiffany's proposals
                are bold and yet achievable, and will help make New York City a
                safer and more just place for all of our communities and all of
                our people."
              </p>
            </section>

            <section id="alex-vitale">
              <p>
                “People are demanding alternatives to using mass criminalization
                to solve community problems brought on by decades of budget cuts
                and growing inequality,” said{' '}
                <strong>
                  Professor of Sociology and Coordinator of the Policing and
                  Social Justice Project at Brooklyn College Alex Vitale
                </strong>
                . “This document provides a concrete plan for how to do that.
                It’s going to play a central role in reshaping the conversation
                about improving public safety and in the process, change the
                political landscape of New York City.”
              </p>
            </section>

            <section id="citizen-action">
              <p>
                “To honor the lives of those stolen by police and state
                sanctioned violence, it is important we take the steps to reckon
                with racist policing and do the work to redefine public safety,”
                said{' '}
                <strong>
                  New York State Political Director of Citizen Action of New
                  York Stanley Fritz
                </strong>
                . “Tiffany's plan exemplifies reimagining public safety in a way
                that centers the voices of those adversely impacted by the
                justice system—a system that never intended to serve justice for
                BIPOC and TGNC communities.”
              </p>
            </section>

            <section id="vocal">
              <p>
                “Our fight has never just been about divesting from police and
                incarceration. It has always been about the critical investments
                Black and Brown communities need and have been denied. “Police,
                jails, and prisons will never keep our communities safe,” said{' '}
                <strong>
                  VOCAL Action Fund Political Director Paulette Soltani
                </strong>
                . “Public safety must be reimagined entirely, government must be
                restructured, and a massive investment of resources must be
                prioritized to tackle the intersecting issues of poverty,
                homelessness, mental health, and drug use. New York City must
                build a public health and social services workforce to ensure
                housing, services, and care for all."
              </p>
            </section>
          </section>
          <section id="summary">
            <h2>Summary</h2>

            <p>
              New York City must modernize our vision of public safety to fit
              the needs of the new century. Rather than spending billions on
              policing and incarceration, we should create and scale citywide,
              neighborhood-level, community-led safety initiatives. Ending our
              reliance on police is only a part of the solution – the bigger
              piece is properly funding the social services and basics people
              need for stability. Fully funding public safety means fully
              funding services that generate neighborhood stability and
              establishing a comprehensive and scaled-up non-policing emergency
              and crisis response apparatus instead of funding policing. It also
              means giving people in the community adequate resources and
              support to solve community-level problems.
            </p>

            <p>
              This program calls for the development of neighborhood-level
              interventions that can be tailored to the needs of each of our
              city’s 51 City council districts. It includes proposals for: (1)
              Community Safety Centers, which will include family support,
              violence prevention, and mediation services; racism and hate
              response funds; and Crisis Intervention Teams; (2) Integrated
              Service Facilities with on-site safe consumption services,
              treatment programs, peer support, legal and case management
              support for housing, healthcare, immigration, and jobs; as well as
              support systems for people experiencing homelessness, including
              transitory housing, programs for safe respite, mail drops,
              showers, and referrals; (3) Enhanced counseling and health
              services, wraparound services, and restorative programming at
              every school, instead of police; (4) Non-police systems for
              responding to transportation or traffic-related issues; (5)
              Comprehensive mental health and crisis response services,
              including community-based treatment programs and a non-police 911
              health care responder corps; (6) A Civil Life Corps that handles
              quality-of-life related matters and day-to-day issues that require
              resolution but do not warrant involving law enforcement, including
              a civilian Property Protection team that can provide advice on
              crime prevention, take official insurance reports, and authorize
              compensation for losses.
            </p>
            <p>
              The paper includes examples of how these programs would be used,
              some of the community organizations who have already started doing
              this work, and names some of the agencies involved – though the
              plan will require new agencies and initiatives as well. We hope
              that this effort facilitates defunding the NYPD and eliminating
              the roles that the NYPD and incarceration play in New York City’s
              public safety. We highlight some of the community groups that
              could be scaled up as an alternative to policing, by defunding
              police and corrections and through downstream savings. Rather than
              evaluating the problems with specific units or budgetary avenues
              toward defunding policing and prisons, which will be explored in a
              separate paper, our goal is to promote conversations on public
              health approaches to public safety, and create the environment
              necessary for a world without policing.
            </p>
          </section>
          <section id="introduction">
            <h2>Introduction</h2>
            <p>
              New York City must fully fund public safety by investing in the
              things that create stability. This paper outlines a plan to center
              community voices, put people to work in their own neighborhoods,
              and uplift neighborhood-based initiatives that evidence shows will
              create more public safety than police and prisons ever could. Our
              campaign will engage in community discussions that facilitate
              coalition-building and help create a new City Council committed to
              the new vision of public safety our city deserves. We need
              solutions scaled to the size of the problem that is the
              policing-prison-industrial complex and mass incarceration. We need
              to fully fund public safety by fully funding our most impacted
              communities.
            </p>

            <p>
              Police are tasked with responding to problems that they are
              ill-equipped to handle. That includes public health issues related
              to poverty, mental illness, substance use, sex work, domestic and
              sexual violence, youth violence, and more. Cities committed to
              long-term stability should listen to communities and activists,
              defund police and spend that money on communities and public
              health alternatives instead. This alternative system of
              investments would prioritize and scale public health approaches
              based on evidence, including non-police emergency healthcare
              responders, community-based-and-led safety initiatives,
              restorative justice programming, investments in liberation focused
              support personnel, violence prevention, and legal services that
              address housing, healthcare, immigration, disability justice and
              accessibility, employment, or indigenous community-specific needs,
              and other everyday issues. These kinds of investments would
              dramatically reduce the root drivers of problems the system
              currently defines as criminal behavior.
            </p>

            <p>
              The modern American policing-prison complex has always been a
              system of social control designed to facilitate race and class
              exploitation. Slave patrols evolved through Jim Crow and
              broken-windows into the militarized police force we have today,
              and the overcriminalization of a range of social issues has caused
              the mass incarceration of disproportionately Black and brown
              people. There are 2.3 million people incarcerated in America;
              nearly half of the people incarcerated in New York state prisons
              are from New York City. More than 250,000 people are incarcerated
              in New York jails and prisons every year, including thousands who
              are being held in pre-trial detention merely because they cannot
              afford money bail.
            </p>

            <p>
              We spend about $10 billion every year on policing in New York City
              – the 33rd largest military budget on the planet. That’s roughly
              $200 million per council district, each of which include about
              160,000 people.
            </p>

            <p>
              What do we get for that investment? New York City is overpoliced
              and over-incarcerated, and its elected leaders have systematically
              disinvested from communities historically oppressed by racist
              policies. Hundreds of people in our city have died due to
              police-involved activity just this past decade. Nationwide, there
              have been 5,000 police-involved killings since 2015, half of which
              involve someone with disability or mental illness.
            </p>

            <p>
              Police disproportionately make arrests in poorer, non-white
              communities by criminalizing poverty, mental illness, substance
              use, and sex work. Prosecutors and courts then apply brutally
              harsh sentencing schemes, putting people in jails and prisons that
              exacerbate violence and do not help people through crises or
              prevent future crime.
            </p>

            <p>
              For generations, this nation has refused to invest in the things
              that really keep people safe: housing, health care, job
              opportunities, community-led safety initiatives, and public health
              solutions to public health problems. Fully funding those programs
              is a better way to fully fund public safety.
            </p>

            <p>
              Police do not solve most crimes and do nearly nothing to help
              survivors and victims. Rates of legal accountability for serious
              crimes hovers just above 10% based on the little data police make
              available, and most people who commit murders and rapes are not
              held accountable. Moreover, because police can manipulate data and
              even institute racist arrest quotas, clearance rates themselves
              seriously overestimate the actual rate at which people are held
              accountable when a crime is committed. If someone is in a
              situation where they need to call 911 because they are afraid for
              their life, police are unlikely to be able to help. The last thing
              survivors need is the perpetuation of harm that policing and
              prisons inflict; rather, most survivors and victims want services
              tailored to their needs.
            </p>

            <p>
              What do we need to do as a society to prevent as many bad outcomes
              and reduce as much harm as possible? The answer is investing in
              services and resources that prevent bad outcomes from happening.
              People who have access to opportunity, who grow up in a stable
              environment, and who feel confident that their future is secure
              almost never commit a serious violent crime like murder. Tragedies
              will still occur, unfortunately, and society must hold people
              accountable when harm is done. But the system that exists today
              does not prevent or resolve these tragedies, and the system’s cost
              – the generational destruction of disproportionately poorer Black
              and brown neighborhoods – means the solution of policing and
              prisons is worse than the problem it is trying to address.
            </p>

            <p>
              Our city has spent too many billions of dollars on an agency that
              uses gas on the people who live here, runs us over with cars,
              evicts us from our homes, tells us who qualifies as a journalist
              and who doesn’t, separates us from our families, deports us, rapes
              us, kills us – with no accountability. The solution to the
              problems of our time is not spending more money on racist,
              classist tools of oppression and violence.
            </p>

            <p>
              Here are some of the community-led efforts that we could spend
              that money on instead.
            </p>
          </section>
          <section id="community-safety-centers">
            <h2>Community Safety Centers</h2>
            <p>
              Despite massive spending on policing and jails, people do not feel
              safe. People living in New York City continue to experience
              domestic violence, youth violence, gun violence, and police
              violence. This section outlines Community Safety Centers, one-stop
              shops for community-led alternatives to criminalization that focus
              on violence interruption, restorative justice and services-based
              approaches, expanding on existing City programs like the Crisis
              Management System and community neighborhood-based approaches.
              Every neighborhood could have a non-police, community-based center
              for comprehensive crisis intervention, mediation, supportive
              services, and violence prevention.
            </p>

            <p>
              In addition, police fail to respond to trafficking and instead
              criminalize sex workers in a way that prevents accountability for
              actual traffickers. Sex work is work and must be fully
              decriminalized; the government should have no place in the bedroom
              of consenting adults. Where people are concerned about harm to sex
              workers or orderliness, Family Support services could send teams
              capable of providing support for runaway and vulnerable youth,
              income supports for women in vulnerable positions, as well as
              trauma services for those surviving childhood sexual abuse.
            </p>

            <p>
              We need to invest in comprehensive family support programs, peer
              support, youth violence intervention programs, youth job programs,
              credible messenger systems involving violence disruption by
              trained experts including people recently released from
              incarceration, community mediation, community violence centers
              able to meet the needs of 160,000 people in a Council District,
              and fully funded community-based crisis intervention teams that
              can prevent violence and de-escalate situations. Wherever possible
              these supports will be provided by trained and compensated members
              of the community who are closest to the problem. These services
              will be non-coercive – they will be offered to people, rather than
              required of people.
            </p>

            <section id="family-supports">
              <h3>Family Supports</h3>
              <p>
                Community Safety Centers would include Family Support services
                that work with and in households to improve parent and child
                education, provide counseling, address gender violence with
                support and services, pursue disability justice and offer
                education and services, and help disadvantaged families or young
                people. Studies show that families who receive family-centered
                support are satisfied with the care they receive, and that such
                care can reduce incidents of child abuse or neglect in families
                by 29% or more.{' '}
              </p>
              <p>
                Establishing family support centers in every neighborhood would
                ensure families can have access to healthcare information about
                their children, treatment from vaccinations to mental
                healthcare, peer support, counseling, legal aid, gender violence
                support, wraparound services, income supports, and intensive
                therapy as needed to help keep families together and reduce
                conflict.{' '}
              </p>
              <p>
                New York has started these kinds of programs. But as happens all
                too often in our service provision sector, employee turnover has
                been high due to better salaries elsewhere. We must pay a staff
                of social service and care providers good salaries with benefits
                and socially prioritize funding for these programs, and ensure
                social service providers have the right to organize without
                retaliation. Despite ongoing fiscal challenges, the NYC programs
                have demonstrated significant success worth scaling: they can
                reduce child displacement from homes, and they can help families
                better prevent future incidents.
              </p>
              <p>
                Example of Use: A parent is struggling with alcohol dependency
                and the family has experienced trauma. The family can come to
                the Community Safety Center for Family Support services. The
                parent can get medical care, individualized counseling, and
                parenting skills classes, while the center provides childcare
                and individualized support to help the child develop healthy
                coping mechanisms. All family members can access these services,
                and medical staff on-site will be available to answer urgent
                support questions 24/7.
              </p>
              <p>
                Example of Use: A community member who recognizes a sex worker
                who seems to be underaged, or suffering from some physical or
                emotional harm is able to refer them to Family Support, who can
                support them through trauma services, housing, and healthcare
                services.{' '}
              </p>
              <p>
                Example of Use: A person experiences intimate partner violence.
                They can come to Family Support for holistic services,
                counseling and access to legal support. If their immigration
                status depends on their partner, they can get an immigration
                attorney. If they have a child, the child can receive
                individualized care and tools to cope with the trauma. If they
                want to separate from their partner, Family Support can ensure
                they have a place to go and everything they need including
                facilitating access to housing, employment, healthcare,
                education and long-term support.
              </p>

              <h4>Agencies Involved</h4>
              <p>
                Department of Education (DOE), Human Resources Administration
                (HRA), Department of Youth & Community Development (DYCD),
                Office of Neighborhood Safety (ONS).
              </p>

              <h4>Examples of Community Organizations Doing This Work </h4>
              <p>
                Sex Workers Project, Safe Horizon, Anti-Violence Project, and
                others.{' '}
              </p>
            </section>
            <section id="violence-prevention">
              <h3>Violence Prevention Services</h3>
              <p>
                Young people are developing and vulnerable to trauma. This
                biology is mostly lost on the criminal legal system.
                Behaviorally, our brains develop through the age of 25 – yet
                police activity disproportionately involves going after young
                people. Young people who are acting out, exhibiting violent or
                antisocial behavior do not need police.
              </p>

              <p>
                We must provide these young people with holistic wraparound
                services, and facilitate credible messaging programs like LIFE
                Camp, Cure Violence, and others that focus on public health
                approaches to youth violence and gender violence prevention.
                These initiatives work with young people by hiring people who
                are in the community and familiar with its intricacies and
                conflicts to develop individualized strategies that address
                young people’s needs, and the evidence shows they work – these
                kinds of programs can reduce shootings or killings by 70%.
                Violence interrupter presence consistently leads to reduced
                violence and reduced police involvement.
              </p>

              <p>
                Example of Use: A young person is involved in an altercation
                with another young person. People in the area, friends and
                family worry the conflict will escalate into violence. Credible
                messengers from the community who have similar experiences and
                are engaged in long-term relationship building intervene. They
                work to establish processes and coping mechanisms that allow the
                young people to heal and to break cycles of violence. They help
                train the young people in de-escalation techniques, equipping
                them with tools to cool off.
              </p>

              <p>
                Example of Use: Two young people get into a dispute at school.
                Violence interrupters respond to the school premises. The team
                goes in, and, if necessary, physically separates the two young
                people. The peer interrupters talk to the young people and help
                equip them with the tools to resolve the conflict. No
                escalation. No retaliation.
              </p>

              <h4>Agencies Involved</h4>
              <p>
                Mayor’s Office of Criminal Justice (MOCJ), Mayor’s Office to
                Prevent Gun Violence (OPGV)
              </p>
              <h4>Examples of Community Organizations Doing This Work </h4>
              <p>
                Life Camp, 696 Build Queens Bridge, Rock Safe Streets, and
                others.
              </p>
            </section>
            <section id="mediation">
              <h3>Community Mediation Centers</h3>
              <p>
                Police are often tasked with responding to non-criminal
                neighborhood-level conflicts, and it is a matter of luck whether
                the responding officer is both familiar with the people involved
                or their backgrounds and trusted enough to help de-escalate a
                conflict. Community-based mediation would enable trusted
                community members to resolve conflicts.
              </p>

              <p>
                Evidence on community mediation centers shows that they lead to
                broader satisfaction than policing and prisons, and generate
                buy-in from community members. Neighborhoods that can mediate
                their own conflicts tend to engender their own stability, which
                is a recipe for longer-term success.
              </p>

              <p>
                If members of a community are involved in a dispute over
                property, noise complaints, inappropriate conduct or some other
                hyper-local matter, other members of that community will be
                better equipped with the knowledge and understanding to resolve
                the conflict, particularly if we work with them on training and
                outreach so that every person knows where and how to resolve a
                conflict without involving law enforcement.
              </p>

              <p>
                Example of Use: A bodega owner and group of teenagers are
                getting into weekly disputes about noise disturbances and
                roughhousing. A team from the Community Mediation Center would
                respond to the situation and engage the teenagers and bodega
                owner in a mediation process using a restorative approach that
                facilitates space for the young people to express themselves and
                develop tools of resilience while meeting the bodega owner’s
                need for a calm and safe environment.
              </p>

              <h4>Agencies Involved</h4>
              <p>HRA, MOCJ</p>
              <h4>Examples of Community Organizations Doing This Work </h4>
              <p>Common Justice, and others.</p>
            </section>
            <section id="violence-mediation-centers">
              <h3>
                Violence Mediation Centers and Community-based Alternatives to
                Incarceration
              </h3>
              <p>
                In the event violence occurs in the community, police are used
                as a blunt instrument – in other words, they perpetuate violence
                against the people involved. Rather than address instances of
                violence with policing and prisons, we could work with programs
                like Common Justice and others, proven to work and developed
                right here in New York City, showing the way on non-carceral
                alternatives to instances of violence.
              </p>
              <p>
                We could establish centers that can meet the needs of the
                160,000 people in each Council District. If there is an act of
                violence, the approach would aim to center restorative responses
                that bring impacted people together and center their needs. That
                will mean different forms of accountability than the carceral
                response; it will mean prioritizing helping survivors and
                victims as much as possible, working with survivors and victims
                of gender violence to address their needs and trauma,
                identifying the root causes that led to an incident and working
                to address it, providing clear and swift accountability that
                focuses on trying to make the community whole rather than
                throwing someone in a cage, and engaging in long-term holistic
                dialogue aimed at lasting resolution, if and when appropriate.
              </p>
              <p>
                Communities will be empowered to develop individualized
                strategies to help heal wounds of violence rather than being
                forced to rely on policing and prisons as the only recourse.
              </p>
              Example of Use: A person is beat up, suffers a broken rib, and is
              robbed of his wallet. First, his needs are heard and affirmed, and
              he works with trained staff to develop a services and healing
              plan. Then, when he is ready, he participates in restorative
              justice circles with the person who harmed him and their
              respective support systems. He asks questions and gets answers,
              and they develop an accountability and consequences plan.
              <h4>Agencies Involved</h4>
              <p>HRA, MOCJ</p>
              <h4>Example of Community Organization Doing This Work</h4>
              <p>Common Justice, and others.</p>
            </section>
            <section id="racism-response-fund">
              <h3>Racism, Hate and Violence Response Funds</h3>

              <p>
                When a community goes through its worst days – enduring an act
                of racism, hate or violence, including gender violence – the
                primary response available to them is policing. But police
                perpetuate systemic racism and violence, including in responding
                to cases of racism and violence. Two wrongs do not make a right.
                Police are also often perpetuators of racist or hate-based
                violence. Instead of addressing racism, hate or violence with
                more of the same, we could establish funds that allow people who
                have experienced an act of racism or violence to receive
                compensation.
              </p>

              <p>
                These funds would also allow people experiencing gender, youth
                or any type of violence to receive emergency funds, without
                police involvement. Emergency relief could help people get
                through difficult crisis situations, and the person could
                connect with additional Community Safety Center and Integrated
                Service Facility resources to access emergency housing
                counseling, peer support and other services they may need.
              </p>

              <p>
                In conjunction with other programming aimed at restoring and
                helping people through services and support, compensation for
                acts of racism or violence can provide people with assurance
                that their trauma will not compound into further trauma based on
                new financial difficulty. And in a very real sense, receiving
                compensation for such an act can help provide closure and a
                sense of justice without the tremendous deleterious baggage of
                the policing-prison system. These Funds would also work to
                engage in a restorative justice process for offenders, and
                publish a report on the incident to reckon with and begin a
                reconciliation process. The system would provide victims with
                support needed to get through the crisis. Racist actions have
                systemic, racist roots. They are a product of our racist society
                and we have a collective responsibility to repair that damage.
              </p>

              <p>
                Example of Use: A Black person who is bird-watching is subjected
                to racist insults and a police call, infringing their civil
                liberties and dignity. The person can turn to the New York
                Racism and Hate Response Fund and receive financial compensation
                for the emotional harm and life disruption.
              </p>

              <p>
                Example of Use: A Muslim woman is accosted by someone for
                wearing a hijab at a local market. She could apply for financial
                compensation from the New York Racism and Hate Response Fund for
                any harm or trauma imposed by the event.
              </p>

              <p>
                Example of Use: A mother who experiences abuse by the father of
                her children needs support but does not want him incarcerated.
                She could work with the appropriate support programs and apply
                for the New York Violence Response Fund and receive financial
                compensation.
              </p>

              <p>
                Example of Use: An indigenous community has been denied relief
                for property theft. The community could seek a reconciliation
                and relief process through the New York Racism and Hate Response
                Fund, regardless of when the theft occurred, to engage in
                restorative approaches that seek to recognize harms done and
                work with the community to identify and pursue pathways to
                relief.
              </p>

              <h4>Agencies Involved</h4>

              <p>
                City Commission on Human Rights (CCHR), Racial Justice and
                Reconciliation Commission, new agency
              </p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Anti-Racism Response Network, mutual aids including Equality for
                Flatbush, Mutual Aid Fund for Sex Workers of Color, Flushing
                Workers Center, Astoria Mutual Aid Network, Queens Care
                Collective, Street Vendor Project, Make the Road, VOCAL, New
                Sanctuary Coalition, and others.
              </p>
            </section>
            <section id="non-police-teams">
              <h3>Non-Police Crisis Intervention Teams</h3>

              <p>
                When people are experiencing a crisis such as incidences of
                domestic, gender or youth violence, police are tasked with
                responding and addressing the situation. Too often, that
                response results in use of deadly force – but even when it does
                not, it can escalate the situation rather than resolve it.
                Civilian Crisis Intervention Teams (CITs) composed of violence
                reduction-focused de-escalation and behavioral health experts
                are better equipped than police to respond to these situations.
              </p>

              <p>
                Expanding crisis intervention programs and training, including
                for existing programs like Intensive Mobile Treatment teams
                (IMTs), can help mitigate local problems without involving
                police by enabling trusted community members and experts to join
                and spend extensive time learning about crisis intervention.
                These teams could help respond to a case of domestic, sexual,
                community-based, or youth violence. The groups could work as
                violence interrupters, linked to social workers that help people
                who are in crisis. Members of the CIT would regularly interact
                with people in the community and people who come to the
                Community Safety Center, so that their arrival on a scene
                includes someone who is known to people involved in the
                incident. Trained experts on sexual, domestic or youth violence
                mitigation are more likely to be able to successfully intervene
                and resolve or mitigate these kinds of crises.
              </p>

              <p>
                Example of Use: Two people in a long-term relationship are
                experiencing hardship, and one partner begins emotionally and
                physically abusing the other. CITs who are from the community
                and know both people respond to the situation, physically
                separate the pair if necessary, and engage them in a restorative
                process that centers the needs of both and ensures the person
                abused has resources, services and tools in place to feel safe
                and stable. They are connected to Family Support and other
                appropriate services for longer-term treatment.
              </p>

              <h4>Agencies Involved</h4>

              <p>Department of Health and Mental Hygiene (DOHMH)</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Community Access, Correct Crisis Intervention Today-NYC
                (CCIT-NYC), VOCAL, National Alliance on Mental Illness-NYC Metro
                (NAMI-NYC), and others.
              </p>
            </section>
          </section>
          <section id="integrated-service-facilities">
            <h2>Integrated Service Facilities</h2>
            <p>
              Our city could establish Integrated Service Facilities citywide
              that provide comprehensive substance use treatment, overdose
              prevention services, and legal support for housing, employment, or
              other issues that arise. Deeply affordable, sustainable housing is
              fundamental to stable neighborhoods. Rise of crime is highly
              connected to economic hardships including struggles to pay for
              rent or utilities. Our city has wasted money trying to incentivize
              affordable housing through private development for too long. In
              order to solve our housing crisis, we need to renovate and develop
              green, mixed income housing that every person can afford.
            </p>

            <p>
              We must eliminate homelessness in our city by providing a home to
              every person experiencing homelessness. We must create and scale
              mitigation programs that provide people with a place for safe
              respite, mail drops, showers, and access to housing support
              services.
            </p>

            <section id="housing-services">
              <h3>Housing Support Services</h3>
              <p>
                Housing precarity and homelessness are citywide crises. Right
                now, police are tasked with carrying out evictions and with
                responding to people experiencing homelessness, and the
                interactions frequently result in violence – whether it is
                removal or physical harm. Police disproportionately hurt or kill
                people who are experiencing homelessness, and should be removed
                entirely from responding to situations involving people
                experiencing homelessness and other housing issues.
              </p>
              <p>
                Services facilities would include support for housing –
                transitory housing, arrears payment funds to keep people in
                homes, support to ensure people are shifted into the shelter
                system, legal services for residents experiencing problems with
                rent or their mortgage, and a centralized location for people to
                turn to when they go through displacement or housing problems.
                That will include beds, 24/7 support staff, and a direct line
                available through 911 operators when a housing issue arises,
                including options to help transport people and their belongings
                to a safe space.
              </p>
              <p>
                Example of Use: A young person is kicked out of his home. They
                can immediately reach out to an emergency operator who will
                connect them to the local Integrated Service Facility and engage
                Housing Support to come pick them and their belongings up,
                provide them with a temporary safe space to sleep, and help them
                secure relevant legal counsel and support services.
              </p>
              <p>
                Example of Use: An older couple is experiencing financial
                hardship and is no longer able to pay their mortgage. Their
                lender is threatening to take their home. They can contact
                Housing Support to connect with emergency rent and mortgage
                relief, as well as legal counsel and financial planning advisers
                to help chart a course through their housing precarity.
              </p>
              <h4>Agencies Involved</h4>
              <p>HRA, Department of Social Services (DSS)</p>
              <h4>Examples of Community Organizations Doing This Work</h4>
              <p>
                Fortune Society, Community Access, Neighborhood Coalition for
                Shelter, The Doe Fund, Neighborhood Care Team, and others.
              </p>
            </section>
            <section id="safe-respite">
              <h3>Safe Respite Homelessness Mitigation Programs</h3>

              <p>
                Police sweeping camps of people experiencing homelessness and
                making arrests does not solve the root issue, and makes the
                situation worse for the people involved. It also costs a
                tremendous amount of money to incarcerate people in jails or
                prisons. People who are in between housing need mitigation
                programs and access to a safe space for respite, including mail
                drops, showers, laundry and access to basics.
              </p>

              <p>
                Ensuring guaranteed access to these kinds of services will mean
                every person has a place they know they can go to take care of
                daily needs. Outreach programs can connect trained experts with
                people experiencing homelessness to increase access to housing
                opportunities and transitory support systems.
              </p>

              <p>
                Example of Use: A person is experiencing homelessness. They have
                a job interview coming up. They can come to the Safe Respite
                mitigation center to shower, clean their clothes, drop off and
                receive mail, rest for a short period of time and prepare for
                the job interview.
              </p>

              <h4>Agencies Involved</h4>

              <p>Department of Homeless Services (DHS)</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Neighborhood Coalition for Shelter, Coalition for the Homeless
              </p>
            </section>

            <section id="substance-use">
              <h3>Substance Use Treatment Programs</h3>

              <p>
                People with substance use disorder are frequent targets of
                policing and end up incarcerated, even though incarceration
                often exacerbates problems related to substance use. Relapse
                itself is particularly dangerous after incarceration – people
                who are reentering are particularly vulnerable to returning to
                drug use and to overdosing. Police and prisons do not address or
                improve problems stemming from substance use disorder. Substance
                use treatment programs can.
              </p>

              <p>
                Ensuring every neighborhood has access to comprehensive
                substance use care can help turn the tide of the opioid epidemic
                and the broader issues with substance use challenging our
                society. Treatment programs with inpatient and outpatient
                capacity can equip people with tools to cope with substance use
                issues, facilitate recovery, provide peer support, and ensure
                quality healthcare at every point of the recovery process. These
                kinds of programs may require years of proactive engagement with
                people to ensure long-term stability, because recovery is a
                lifelong process. Service facility treatment programs would be
                capable of engaged long-term care.
              </p>

              <p>
                We must also look at the underlying forces that drive harmful
                substance use. Long term unemployment, untreated mental health
                problems, unaddressed childhood traumas all play a role and
                could be addressed through better access to stable incomes, high
                quality mental health services, and trauma counseling.
              </p>

              <p>
                Example of Use: A person is struggling with opioid dependency.
                In the course of the pandemic, they have used opioids multiple
                times after years of sobriety. They recognize they need help,
                and engage with loved ones to reach out to the Substance Use
                Treatment Program to receive counseling, treatment, and
                inpatient treatment followed by outpatient care.
              </p>

              <h4>Agencies Involved</h4>

              <p>DOHMH</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Make the Road, VOCAL, Injection Drug Users Health Alliance, and
                others.
              </p>
            </section>
            <section id="overdose">
              <h3>Overdose Prevention and Safe Consumption Services</h3>

              <p>
                Safe consumption services are a core component of saving lives
                in the midst of the opioid epidemic and its effect on HIV,
                hepatitis and other infectious diseases. Safe consumption
                services are particularly important during public health crises
                including a pandemic. Overdose prevention services including
                supervised consumption sites and the accompanying treatment
                programs have demonstrated substantial success wherever they are
                implemented. These facilities prevent overdose deaths on-site,
                and reduce county-wide overdose death rates by about 30%.
              </p>

              <p>
                This kind of intervention is a core aspect of reducing the tens
                of thousands of overdose deaths nationwide and the thousands of
                deaths in New York City. In addition to the moral imperative,
                there is strong fiscal sense in establishing safe consumption
                services. Overdose prevention sites cost roughly $2 million per
                site, and studies show they save some $6 million per site in
                downstream healthcare costs.
              </p>

              <p>
                These sites are medical facilities that could be created in
                order to cope with an ongoing epidemic, providing preventative
                measures from narcan to clean needles to condoms. We could build
                on existing local efforts and establish these facilities as
                quickly as possible.
              </p>

              <p>
                Example of Use: A person purchases intravenous drugs, but is
                worried about impurities that could lead to overdose death, and
                does not have access to a clean needle. They can go to a Safe
                Consumption Services facility for a clean needle, as well as
                have the drug tested for purity to eliminate the risk of
                overdose and other health related complications. They can use at
                the facility, and in the event they do experience health
                complications, on-site medical personnel can apply life-saving
                treatment like Narcan. When the person leaves the facility, they
                take Narcan and other treatment information with them to share
                with their community.
              </p>

              <h4>Agencies Involved</h4>

              <p>DOHMH</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Injection Drug Users Health Alliance, Make the Road, Vital
                Strategies, VOCAL, and others.
              </p>
            </section>
            <section id="legal-support">
              <h3>Legal Support Clinics</h3>

              <p>
                People who are experiencing everyday problems related to
                immigration, housing, employment, sexual or domestic violence,
                child care, elder care, retirement help, estate planning or
                other basic needs frequently have nowhere to turn to for
                quality, free and tailored advice. Comprehensive, quality and
                free Legal Support Clinics would be available at Integrated
                Service Facilities, including for these problems and more.
                Quality and comprehensive legal care should be guaranteed,
                similar to healthcare and housing; Legal Support Clinics will be
                fully staffed with attorneys, and will partner with independent
                and private legal service providers.
              </p>

              <p>
                Police responding to issues stemming from lack of adequate legal
                support often make the legal position of people involved worse.
                In the same way our city should provide fully funded public
                defense to every person charged with a crime, it should also
                provide fully funded civil legal support services to every
                person who needs help addressing problems that arise from
                everyday life.
              </p>

              <p>
                Example of Use: ICE arrests and is seeking to deport a family
                member. The person can turn to a Legal Support Clinic for
                comprehensive, free immigration representation. The family can
                receive additional legal support for problems that arise related
                to housing or employment.
              </p>

              <p>
                Example of Use: A person experiencing workplace harassment is
                afraid of employer retaliation for pursuing a complaint. They
                could turn to a Legal Support Clinic for legal services and
                representation that protects them against retaliation.
              </p>

              <p>
                Example of Use: A person with a disability requests installation
                of an accessible entryway at their apartment building. The
                landlord does not promptly comply. The person can come to a
                Legal Support Clinic to get legal help to compel installation if
                necessary, and referral to Civil Engineer Corps services for
                short-term relief.
              </p>

              <h4>Agencies Involved</h4>

              <p>HRA</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>Legal Aid Society, Legal Services NYC, and others.</p>
            </section>
          </section>
          <section id="school-support">
            <h2>School-Based Support Instead of Policing</h2>

            <p>
              We need school counselors, not cops. School Safety Agents (SSAs)
              do not help our students’ education, and they do not even make
              young people safer – if anything, the presence of SSAs leads to
              the increased likelihood of arrest or upcharging minor problems
              into serious legal issues, and research shows Black students are
              monitored more closely and punished more frequently than white
              students, beginning at pre-school.
            </p>
            <p>
              SSAs and invasive security measures including metal detectors
              should be entirely removed from schools, and the School Safety
              Division should be disbanded. Instead, our city should spend that
              money on individualized, targeted strategic resource investment in
              students and teachers, including social, emotional and mental
              health support staff for students and families, that can help
              ensure every young person has the opportunity to succeed.
              Guaranteeing young people have access to counselors and supportive
              services both increases their chances of success and keeps our
              communities healthier and safer. Restorative programs and
              wraparound services address problems in student’s lives without
              subjecting them to school discipline or juvenile detention
              proceedings that only make things worse.
            </p>

            <section id="counselors">
              <h3>Counselors and Medical Staff Funding</h3>

              <p>
                School Safety Agents are police in schools that are supposed to
                promote student safety but in practice end up over-policing
                disproportionately Black and brown students. Although young
                people do not misbehave at different rates based on race, the
                presence of police in schools means a Black student is 2.3 times
                more likely to be arrested for an incident than a white student.
              </p>

              <p>
                In our school system, which is among the most segregated public
                systems in the nation, this kind of disparity means some schools
                have police who send young people to jail, while others do not –
                for the same conduct. Evidence shows that students who have
                experienced trauma or emotional distress frequently do not have
                access to counseling services. Yet lack of adequate funding for
                school healthcare staff during the pandemic means cops are
                helping take students’ temperatures.
              </p>

              <p>
                Schools should be centers for learning. Students need counselors
                and nurses to receive comprehensive emotional and physical
                healthcare. Every school should be equipped with a staff of
                trained healthcare experts who can provide students with
                whatever treatment they need or identify appropriate follow-up
                for care outside of school. We should replace school police with
                counselors, nurses, and medical staff that can ensure our
                children can receive the care that they need to learn
                effectively.
              </p>

              <p>
                Example of Use: A group of students has escalating interpersonal
                relationship problems at school. One-on-one consultations reveal
                they are experiencing various issues driving their conflict. A
                team of school counselors work with the students to teach them
                how to build healthy relationships with one another, understand
                how and why the conflicts arose, and develop tools together on
                acceptable ways to identify and resolve conflict. Where
                appropriate, family members are engaged for Wraparound Services.
              </p>

              <h4>Agencies Involved</h4>

              <p>DOE</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                The Door, NAMI-NYC, Community Access, Dignity in Schools, and
                others.
              </p>
            </section>

            <section id="targeted-strats">
              <h3>Individualized Targeted Strategies</h3>

              <p>
                Schools that spend money on policing should instead invest in
                individualized targeted strategies for students. Evidence shows
                that these kinds of strategies can increase graduation rates
                dramatically, and facilitate future education and employment
                opportunities.
              </p>

              <p>
                Targeted supportive services including mental health
                specialists, learning and physical disability specialists,
                gender and youth violence prevention experts, trauma experts,
                and additional support systems are better equipped to help young
                people learn and succeed than police. Supporting students in
                this way will not only better address crises as they unfold,
                they can prevent crises from happening in the first place and
                can ensure students are on the path to self-sustaining and
                socially beneficial lives.
              </p>

              <p>
                Example of Use: A social worker identifies a student who is
                experiencing mental health difficulties that result in
                disruptive behavior in the classroom. The social worker works
                with the student to address the root issues in an individualized
                setting. The worker consults with a team including counselors,
                teachers and other experts to develop a holistic plan that
                addresses the student’s needs, and engages in a long-term effort
                to help resolve them.
              </p>

              <h4>Agencies Involved</h4>

              <p>DOE</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Understood, Dignity in Schools, The Children’s Aid Society,
                ARISE Coalition, Make the Road, and others.
              </p>
            </section>

            <section id="youth-restorative">
              <h3>Youth Restorative Justice Programs</h3>

              <p>
                Violence prevention has to start early. When police intervene,
                conflicts are escalated and can result in discipline or even
                arrest. Effective violence prevention and restorative justice
                programming should be available at all points of the day.
                Restorative programs can resolve conflicts rather than imposing
                violence to force them to stop.
              </p>

              <p>
                Restorative justice programs and mediation experts can
                de-escalate conflict and facilitate resolution through dialogue.
                These kinds of interventions are longer-lasting and equip
                students with tools to cope with future situations. Addressing
                the root problems leading to violent incidents with support and
                restorative approaches will facilitate broader social change in
                how we approach violence, and develop systems for intervening at
                developmental stages that are historically most closely
                associated with violent or antisocial behavior currently
                addressed through policing, detention and incarceration.
              </p>

              <p>
                Example of Use: Two students have an altercation that ends in a
                fistfight. A team of restorative justice experts works with the
                students to talk about the circumstances leading to the
                conflict, the issues in their lives that need addressing, and
                when appropriate a consensual dialogue that aims to resolve the
                conflict and equip the students with language and processes to
                de-escalate future conflicts.
              </p>

              <h4>Agencies Involved</h4>

              <p>MOCJ, Department of Youth & Community Development (DYCD)</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Restorative Justice Initiative, El Puente, Girls for Gender
                Equity, VOCAL, Brooklyn Restorative Justice Project, and others.
              </p>
            </section>

            <section id="wraparound">
              <h3>Wraparound Services</h3>

              <p>
                Students exhibiting behavioral problems often receive escalating
                discipline and, when police are involved, may eventually become
                entangled with the criminal legal system. This response
                exacerbates crises and creates new ones. Just as police are
                incapable of resolving long-term mental health problems, police
                cannot resolve the multiple forms of trauma young people can
                experience.
              </p>

              <p>
                Every school should be equipped to provide wraparound services.
                That means schools need staff with family support experts who
                can engage teams including family members to help students
                overcome problems impacting their ability to learn.
                Incorporating family and loved ones into the support process is
                critical to ensuring long-term success, and can help equip
                family members with tools to support students in their home
                environment.
              </p>

              <p>
                Example of Use: Students in an altercation receiving
                individualized strategies identify family problems as a source
                of their in-school issues. Wraparound Services engages families
                and loved ones with holistic long-term counseling and skills
                training to address out-of-school issues that arise and lead to
                or exacerbate student conflict.
              </p>

              <h4>Agencies Involved</h4>

              <p>DOE, DYCD</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Coalition for Community Schools, Network for Youth Success,
                North American Family Institute-New York (NAFI-NY), and others.
              </p>
            </section>
          </section>

          <section id="traffic">
            <h2>Non-Police Traffic Safety</h2>
            <p>
              Evidence shows police do not contribute to traffic safety. Police
              should be removed from traffic-related enforcement. Traffic
              violations can be handled by civilian teams, non-biased automated
              systems and concerted education-based outreach. Where necessary,
              teams of non-police unarmed responders can assist in emergency
              situations.
            </p>
            <p>
              Transportation safety must include comprehensive investment in
              street infrastructure, free public transportation and urban
              planning that makes our streets safer, including bike lanes and
              safe open spaces for pedestrians.
            </p>

            <section id="traffic-responders">
              <h3>Non-Police Traffic Responders</h3>

              <p>
                Police involvement in traffic response is one of the most
                dangerous areas of civilian-police interactions. People have
                been killed for reaching for their driver’s license, arguing
                their innocence, or simply being in a car with a panicking
                police officer nearby or as a result of a high speed chase.
                About 11% of police-involved killings in 2015 occurred during
                traffic enforcement, with disproportionately Black victims.
              </p>

              <p>
                Writing tickets is not worth lives, and there are better ways to
                enforce traffic safety rules. Non-police traffic responders,
                including a Department of Transportation Collision Investigation
                Squad, could operate in teams to respond to emergency situations
                on the road, and call in additional support in the event of
                medical situations. Removing police from these situations would
                eliminate a high-risk area of police-civilian interaction.
                Traffic responder teams of experts trained in de-escalation,
                auto mechanics and first aid provision are better options for
                roadway emergencies.
              </p>

              <p>
                Example of Use: A reckless driver causes a collision. A
                Collision Investigation Squad would examine the circumstances
                and causes of the collision, and compare it to automated system
                and city-wide data. A team of experts would work with the
                drivers involved to address the root causes behind the
                collision, provide the drivers with services to get through the
                trauma, and sustain outreach to prevent future collisions.
              </p>

              <h4>Agencies Involved</h4>

              <p>Department of Transportation (DOT)</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Transportation Alternatives, Families for Safe Streets, and
                others.
              </p>
            </section>

            <section id="driver-safety">
              <h3>Driver Safety Education and Outreach</h3>

              <p>
                Police react to driver incidents but do not prevent future
                incidents from occurring – the key to prevention is teams of
                civilian responders trained in de-escalation coupled with
                outreach and education.
              </p>

              <p>
                When a person demonstrates they are incapable of obeying the
                rules of the road, they should be held accountable and
                prohibited from driving but not prevented from earning a
                livelihood – we need alternative transportation mechanisms in
                place sufficient to ensure people can get where they need to go
                without driving. The Outreach program could work with people
                with suspended licenses to ensure problems are not compounded.
                And it could engage in long-term, relationship-based educational
                programming that works with individual drivers to achieve
                sustainable change and safe approaches to road travel.
              </p>

              <p>
                Example of Use: A new driver operates their vehicle recklessly
                on their way to work and endangers a pedestrian, and their
                license is suspended as a result. They are now at risk of losing
                their job. The Driver Safety Education and Outreach program
                would engage in a long-term process that works with the new
                driver to teach safe vehicle operation practices, and provide
                the driver with alternative modes of transportation to work
                until safe conduct is demonstrated.
              </p>

              <h4>Agencies Involved</h4>

              <p>DOT</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Transportation Alternatives, Street Vendor Project, Families for
                Safe Streets, and others.
              </p>
            </section>

            <section id="automated-traffic">
              <h3>Automated Traffic Safety Systems</h3>

              <p>
                Police often work to meet traffic-related citation quotas as a
                mechanism of funding, and frequently use pretexts in traffic
                stops such as the smell of cannabis to disproportionately harm
                Black and brown people. Police were never supposed to control
                public transit safety. Their jurisdiction in this space has
                perpetuated systemic harms, and this harm is untenable.
              </p>

              <p>
                Minor speeding or other traffic violations can result in
                dangerous police-civilian interactions. Establishing automated
                systems with a progressive sliding scale for citations for
                traffic violations in conjunction with civilian responders
                increases traffic safety, but only when such systems do not act
                as a new tax on Black, Latinx or poor communities.
              </p>

              <p>
                Ensuring these systems do not perpetuate invidious racial bias
                is critical to their success. Automated systems must not be
                utilized where they risk such bias. Additionally, these systems
                must not act to expand the surveillance state or include any
                kind of facial recognition or other privacy-invading
                technologies.
              </p>

              <p>
                Example of Use: A driver is speeding in a high-risk area.
                Warning signs indicate the presence of automated cameras. The
                system captures the driver speeding, and the driver is issued a
                citation. If necessary, the Outreach Team connects with the
                driver to develop long-term safe-driving practices.
              </p>

              <h4>Agencies Involved</h4>

              <p>DOT</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Families for Safe Streets, Transportation Alternatives, and
                others.
              </p>
            </section>
            <section id="data-traffic">
              <h3>Data-Driven Traffic Policy</h3>

              <p>
                Major metropolitan areas like New York City should rely on
                data-driven approaches to reduce traffic-related incidents.
                Rather than reactively conducting vehicle pursuits or driver
                stops, the city should examine patterns of incidents and address
                the root problems that lead to them. Implementing
                evidence-based, data-driven reforms across city agencies could
                reduce both city driver and citizen driver traffic incidents. We
                need a paradigm shift around the relationship between our
                streets, cars, cyclists and pedestrians.
              </p>

              <p>
                The city needs to expand its bike lanes and establish safe open
                spaces for pedestrians. Public transportation should work with
                community groups to ensure best practices are followed.
                Accountability for conduct is critical; that response cannot be
                led by policing and incarceration – research shows it is
                counterproductive.
              </p>

              <p>
                Example of Use: All city agencies with drivers implement Vision
                Zero training to reduce city-involved crashes. A city driver
                gets into an accident. The driver receives training and
                education to address any issues behind the accident. The driver
                has the opportunity to demonstrate safe conduct and follow an
                accountability plan including restorative approaches to engaging
                with the survivor or victim. The survivor or victim receives
                support and services to get through their trauma and crisis.
              </p>

              <p>
                Example of Use: City agencies investigate a busy intersection
                where crashes occur. The agencies develop and implement a plan
                that prioritizes pedestrian and biker safety over driver
                convenience, involving adding bus lanes, barriered bike lanes
                and pedestrian guides.
              </p>

              <h4>Agencies Involved</h4>

              <p>
                Transportation Alternatives, Families for Safe Streets, and
                others.
              </p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Families for Safe Streets, Transportation Alternatives, and
                others.
              </p>
            </section>
          </section>

          <section id="mental-health">
            <h2>Mental Health Services and Responders</h2>
            <p>
              Jails and prisons should not be the largest provider of mental
              health treatment in our state. We need to create and scale
              non-police emergency healthcare responders for health-related
              problems. We need to establish community-based services that
              improve responses to individuals in mental health crises by
              ensuring they have access to treatment, emotional support, and
              expert care.
            </p>
            <section id="community-mental-health">
              <h3>Community Mental Health Centers</h3>

              <p>
                In the 20th century, when the federal government reduced funding
                for state mental health institutes, it suggested communities
                would take on the task. Instead, the reality in 2020 is that
                people living with mental illness and the families trying to
                support them frequently have no place to turn. Every community
                could have an established Community Mental Health Center that is
                capable of meeting people’s needs where they are and addressing
                these situations before they get worse. These centers would
                include proactive outreach and service provision centered on
                long-term individualized outpatient support with crisis
                inpatient capacity.
              </p>

              <p>
                Families need a place they know they can get help caring for
                loved ones. Community-based mental healthcare centers could
                ensure people do not have to travel a great distance to receive
                quality mental healthcare. Too often, we see tragedies despite a
                litany of red flags – we must invest in the kind of preventative
                models and long-term support systems that can avert harm. This
                can start with scaled-up peer-based counseling, which Los
                Angeles County is doing and like New York City has begun
                implementing.
              </p>

              <p>
                Example of Use: A family member concerned about the mental
                health of a loved one in their care goes with them to their
                local mental health center where they receive a diagnosis and a
                treatment plan. The family member is given advice on how to
                support their loved one and contacts for follow-up care.
              </p>

              <p>
                Example of Use: A community member who would like mental health
                support but has no current medical support is paired with a peer
                counselor who is trained to counsel outside of a crisis
                situation and can identify someone in crisis and direct them to
                more supportive care.
              </p>

              <h4>Agencies Involved</h4>

              <p>DOHMH</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Community Counseling and Mediation, Institute for Family Health:
                Center for Counseling, and others.
              </p>
            </section>

            <section id="health-corps">
              <h3>Healthcare Responder Corps</h3>

              <p>
                Police are tasked with responding to situations involving people
                going through mental crises. About 25% of people who are living
                with mental illness have reported contact with police, and the
                result is too often deadly – half of all police killings involve
                a person suffering from mental illness. Tasking police with
                responding to mental health problems guarantees deadly outcomes.
                And in situations involving someone with substance use disorder,
                police frequently use intoxication as an excuse for use of
                deadly force. We need to establish an alternative – a non-police
                responder corps that includes de-escalation experts, gender
                violence experts, healthcare providers and peer support for
                healthcare-related situations, as community advocates and
                elected leaders have called for in New York and across the
                country.
              </p>

              <p>
                In Oregon, the CAHOOTS model has shown responding to substance
                use with healthcare reduces subsequent issues and establishes
                treatment pathways that better facilitate long-term recovery
                than a police response. The CAHOOTS program successfully diverts
                20% of all 911 calls and in the process saves local governments
                millions of dollars. Denver, Austin, Los Angeles and other
                cities are implementing similar models at the local level. And
                in New York City, Public Advocate Jumaane Williams has called
                for a comprehensive 911 healthcare option that can address
                behavioral health issues with behavioral health responders,
                substance use issues with experts on addiction, and the multiple
                healthcare problems that can arise with appropriate responders
                including peer specialists and a direct line to non-police
                health experts in situations involving someone considering
                self-harm. This Corps would be fully staffed and equipped to
                handle both emergency 911 situations and daily non-emergency
                problems – including an alternative hotline for people in
                varying kinds of emotional distress who need someone to talk to.
              </p>

              <p>
                Example of Use: A concerned family member calls 911 when a
                person with a history of mental illness threatens self-harm.
                This call is diverted to a non-police emergency responder who is
                an expert in de-escalating mental health crises. They work with
                the patient and concerned family members to develop an ongoing
                care plan and ensure support is put into place to prevent future
                crises before they happen.
              </p>

              <p>
                Example of Use: Someone calls 911 when they witness a member of
                their household overdosing. An ambulance is called, and they are
                paired with a behavioral health responder who can determine
                options for immediate intervention as well as help them develop
                a long-term harm reduction plan.
              </p>

              <p>
                Example of Use: A person is worried that they are experiencing
                problems related to mental health. The situation is not an
                emergency, but the person needs help. The Healthcare Responder
                Corps could respond, establish an initial relationship, and help
                the person access services they need.
              </p>

              <h4>Agencies Involved</h4>

              <p>DOHMH</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Correct Crisis Intervention Today-NYC, VOCAL, National Alliance
                on Mental Illness-NYC Metro (NAMI-NYC), Community Access, and
                others.
              </p>
            </section>
          </section>

          <section id="civil-corps">
            <h2>Civil Life Corps</h2>

            <p>
              Historically, police have responded violently to situations that
              hold our democracy or institutions to account or demand more from
              our government. Our democracy is an organic, living process –
              responding to demonstrations or complaints about quality of life
              with a violent process only exacerbates the issue. Our city could
              expand and establish comprehensive programming that addresses the
              multiple and complicated needs of our civil society to ensure that
              every person’s rights are proactively protected and people are
              regularly engaging in constructive ways with a government that is
              supposed to work for them.
            </p>

            <section id="civil-safety">
              <h3>Civil Safety Corps</h3>

              <p>
                In the event of public demonstrations or social unrest, police
                are tasked with maintaining order, but the result is frequently
                a license for police to harm civilians. Police have even harmed
                de-escalation teams including city elected officials – Public
                Advocate Jumaane Williams was assaulted by an NYPD officer while
                trying to keep the peace during a demonstration in the fall of
                2020. If the NYPD reaction to an elected official’s presence is
                aggression, it is incapable of operating within the confines of
                the social contract promised between a government and the people
                it is supposed to protect.
              </p>

              <p>
                Instead of police responders, people demonstrating could be
                supported with teams of de-escalation and behavioral health
                experts, and a corps of responders trained in crisis management
                who are available to provide food, water, medical treatment, and
                other support for people who are demonstrating. People who are
                demonstrating need assurance that they will not be met with
                assault by NYPD officers, including police vehicles driven into
                crowds, tear-inducing or otherwise harmful chemical compounds,
                physical force including projectiles, batons or melee weapons,
                or any type of serious physical danger from police.
              </p>

              <p>
                Example of Use: Building on the work of organizations who have
                created their own de-escalation and safety training programs for
                demonstrations, the city funds protest safety trainings,
                de-escalation training sessions and offers centralized access to
                volunteer de-escalation/ demonstration marshalling groups to
                make it easier for groups to connect to these groups and receive
                support for upcoming demonstrations (much like people request
                National Lawyers Guild observers now).
              </p>

              <h4>Agencies Involved</h4>

              <p>New agency</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>CCIT, and others.</p>
            </section>

            <section id="democracy-corps">
              <h3>Democracy Outreach and Protection Corps</h3>

              <p>
                New York finally has automatic voter registration, but there is
                much work that needs to be done to ensure every person in the
                city has the opportunity to voice their view on the future of
                the city, including non-citizens. Law enforcement should be
                dissociated from responding to complaints surrounding the
                democratic process.
              </p>

              <p>
                We could instead establish a Democracy Outreach and Protection
                Corps that works before an election to identify every person in
                the city who can vote, and ensure they are registered and know
                how to vote. During elections it would receive resources to
                safely staff polling sites, ensure sufficiency of voting
                locations and poll workers, and provide transportation services
                to people who need help voting.
              </p>

              <p>
                Example of Use: A local resident who has not voted in 10 years
                calls the Democracy Protection Corps or visits the website to
                learn whether they are registered to vote and where they should
                vote, and are given the option to request transportation to
                their polling site.
              </p>

              <p>
                Example of Use: A voter feels they are being intimidated as they
                walk into a polling place. They report the incident to the
                Democracy Protection Corps who can work with poll workers to
                ensure that all requirements, such as party affiliates standing
                the mandated distance from the entrance, are observed.
              </p>

              <h4>Agencies Involved</h4>

              <p>
                Civic Engagement Commission (CEC), DemocracyNYC, New York City
                Campaign Finance Bureau (NYCCFB), Voter Assistance Advisory
                Committee
              </p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Make the Road, VOCAL, Immigrant Defense Project, New Sanctuary
                Coalition, and others.
              </p>
            </section>

            <section id="civil-engineer-corps">
              <h3>Civil Engineer Corps</h3>
              <p>
                Too often, complaints about inadequate housing, public property
                or utilities maintenance end up as a police problem. Disputes
                with landlords can take substantial time to resolve, and may
                themselves be a product of inadequate collective resources for
                housing support and maintenance – there may be instances where a
                landlord wants to fix an issue, but cannot do so without city
                permission. Lead pipes slated for removal may take far longer to
                remove than anticipated.
              </p>
              <p>
                Our city could establish a corps of engineers and maintenance
                workers who are authorized and equipped to repair and maintain
                housing in the event of a dispute with a landlord, identify
                long-standing neighborhood infrastructure issues such as lead
                piping, renovate structures to fit modernized regulations, and
                ensure people experiencing mechanical problems that need fixing
                have a rapid response team available at all times. Where the
                landlord is eventually deemed responsible for the issue, the
                city can ensure financial resolution through expanding and
                collaborating with the Housing Preservation & Development (HPD)
                Alternative Enforcement Program.
              </p>
              <p>
                Example of Use: When someone calls Housing and Preservation to
                report that the landlord has not fixed the heat and hot water
                for over 24 hours, the city sends a maintenance worker to fix
                the issue immediately. If the lack of response is a result of
                neglect, the landlord will be responsible for the cost of
                repairs.
              </p>
              <h4>Agencies Involved</h4>
              HPD, Department of Buildings (DOB)
              <h4>Examples of Community Organizations Doing This Work</h4>
              <p>
                New York Junior League Community Improvement Project (NYJL-CIP),
                Urban Pathways, and others.
              </p>
            </section>

            <section id="civil-equity">
              <h3>
                Civil Equity Research and Data Analysis Transparency Commission
              </h3>

              <p>
                Information access points to civilian-government interactions
                are frequently controlled and often intentionally obfuscated by
                police. Law enforcement oversees comprehensive, well-funded and
                real-time data gathering and analysis efforts, which means
                policymakers looking for information may have few other sources
                available, may receive flawed data and produce flawed policy as
                a result. Civilian data analysts working with neighborhood
                nonprofit and community-based organizations could transparently
                evaluate all city policies in real-time.
              </p>

              <p>
                Existing data transparency laws should be enforced – too often,
                agencies ignore them. Requests for information should be granted
                rapidly. Communities should have control over this process, and
                ensure the public has access throughout.
              </p>

              <p>
                The Civil Equity Research and Data Analysis Transparency
                Commission could facilitate enforcement, and gather and release
                as much data about policies implemented by the city as possible,
                including race and wealth impact analyses. It could work with
                city agencies and elected leaders to ensure consistent standards
                of data collection and transparency, and regularly release
                reports evaluating agency compliance and assessing short-term
                steps to improve compliance. The Commission will also be tasked
                with systematically eliminating use of tools, data and
                algorithms that are demonstrated to use or perpetuate racially
                biased or wealth-based policy outcomes.
              </p>

              <p>
                Example of Use: A journalist needs information on information
                related to policy outcomes in a city agency. The Civil Equity
                Research and Data Analysis Transparency Commission would help
                them identify the data, provide it to them, and ensure follow-up
                questions are answered.
              </p>

              <p>
                Example of Use: A researcher interested in evaluating
                best-practices in municipal government wants to assess the
                benefits of a specific city program. The Civil Equity Research
                and Data Analysis Transparency Commission would share any
                existing analysis and partner with the researcher to publish
                evidence-based findings.
              </p>

              <p>
                Example of Use: A community organization believes that budgetary
                funding for a set of Assessment Algorithms would perpetuate
                systemic racism in an agency. The organization could file a
                complaint with the Civil Equity Research and Data Analysis
                Transparency Commission to halt the program in question and
                ensure it is no longer used or funded by the City.
              </p>

              <h4>Agencies Involved</h4>

              <p>Mayor’s Office of Data Analytics (MODA)</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Progressive Coders Network, Beta NY, Housing Data Coalition, and
                others.
              </p>
            </section>

            <section id="conservation-corps">
              <h3>Conservation Corps</h3>

              <p>
                Green spaces, open areas, public parks, flora and fauna in our
                city are precious resources that must be protected and nurtured.
                Policing parks and environmental issues does not work – it
                perpetuates harm, and rarely holds corporations accountable for
                patterns of environmental misconduct. New York already has a
                state-level conservation corps – our city could model and scale
                up a program based on civil environmental engagement programs
                that have worked across the country.
              </p>

              <p>
                A Conservation Corps could promote environmental resilience
                throughout the city in conjunction with appropriate agencies and
                work in teams to help ensure our green spaces are safe,
                nourished and well-stewarded for future generations. Teams of
                corps members could be on-site at every park to help address
                issues and call for appropriate backup responders when
                emergencies arise. They could also address problems of local
                environmental hazards such as lead paint complaints, hazardous
                waste, and pollution through research, advocacy, and public
                education.
              </p>

              <p>
                Example of Use: A community resident notices what could be a
                hazardous chemical in public. The Conservation Corps safely
                removes the hazard, and works to identify the actor responsible
                for long-term engagement and accountability so that the hazard
                is not reproduced.
              </p>

              <p>
                Example of Use: A local park is trashed and vandalized. Young
                people engage in altercations in the area. Conservation Corps
                members clean the site, and engage appropriate interagency
                collaborations that can address the conflicts to prevent future
                vandalization and harm.
              </p>

              <h4>Agencies Involved</h4>

              <p>Department of Parks & Recreation (DPR)</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>Student Conservation Association, Sunrise, and others.</p>
            </section>

            <section id="non-citizen-corps">
              <h3>Non-Citizen Safety and Outreach Corps</h3>

              <p>
                Police and federal Immigration and Customs Enforcement (ICE)
                authorities work hand in hand to target and frequently terrorize
                immigrants across New York City. Our city must end collaboration
                with ICE and never allow federal officials to police immigrants.
                At the same time, the majority of non-English speaking New
                Yorkers do not receive the services agencies are mandated to
                provide, and receive delayed and worse care when they do get it.
                We need oversight hearings and enforcement plans to address
                these issues. Instead of policing immigration and failing to
                meet the needs of immigrants, we could establish a Non-Citizen
                Safety and Outreach Corps to engage in long-term relationship
                building with people who are newly arrived and long-term
                immigrants.
              </p>

              <p>
                We could guarantee all residents, including immigrants, the
                right to vote in our elections. We must meet immigrants’ need
                for comprehensive legal services with guaranteed access to
                immigration attorneys during proceedings to avoid downstream
                collateral consequences.
              </p>

              <p>
                And we have to address the root insecurities too many immigrants
                experience. Our elected leaders have to hold corporations
                accountable that steal wages or endanger workers. We can also
                protect immigrants by fully funding healthcare, clinics and
                hospitals – the primary points of care for many folks. We need
                language justice in our districts to ensure people can access
                information. We have to ensure immigrant communities receive
                fully funded housing and education opportunities.
              </p>

              <p>
                The Non-Citizen Safety and Outreach Corps would work with
                immigrants to facilitate access to services including legal
                support and housing, field and respond to complaints, and ensure
                the various needs immigrants experience are met. The Corps would
                hire from and work with local immigrant communities to promote
                language justice and removal of access barriers, work with
                people to find employment opportunities, offer education, peer
                and legal support on the citizenship process or other issues
                that arise, and help people secure an attorney in the event of
                federal interference.
              </p>

              <p>
                Example of Use: People newly arrived to the United States move
                into a neighborhood. The Non-Citizen Safety and Outreach Corps
                engages with them to ensure they are receiving language justice,
                access to housing, healthcare, job opportunities and services,
                as well as opportunity to vote in the next local election.
              </p>

              <h4>Agencies Involved</h4>

              <p>Mayor’s Office of Immigrant Affairs (MOIA), new agency</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Immigrant Defense Project, Make the Road, VOCAL, New Sanctuary
                Coalition, and others.
              </p>
            </section>

            <section id="arts-corps">
              <h3>Arts Corps</h3>

              <p>
                New York City fosters a unique and diverse array of artistic
                expressions that drive the culture of the world. Police respond
                to ad hoc art and street-level artistic expression with arrests
                and incarceration. Graffiti, for example, is seen as a threat,
                rather than an opportunity to engage someone who may be
                searching for ways to express themselves artistically. Graffiti
                should not result in arrests. If people living near it do not
                like it, they should not have to think about whether the person
                who made it will end up incarcerated – they should be able to
                turn to a collective of artists to collaboratively put up a new
                mural over the graffiti in question, or engage with the people
                involved to make something the community can be proud of.
              </p>

              <p>
                Instead of policing art, our city could invest in arts
                programming and an Arts Corps that is tasked with beautifying
                every neighborhood and public space in New York City, partnering
                with community organizations to hire local artists to make every
                area beautiful, and highlighting the artistic expression of our
                city through media, entertainment opportunities and, as pandemic
                safety allows, neighborhood block parties.
              </p>

              <p>
                Example of Use: Residents of a building complex are upset by the
                graffiti that covers the side of their building. Rather than
                waiting on a delinquent landlord to fix it, or policing the
                graffiti artist, they work with the NYC Arts Corps to create a
                facade that supports their vision for their building.
              </p>

              <p>
                Example of Use: Residents would like to host a block party. NYC
                Arts Corps drops off street barriers, tables, etc., connects
                residents to local artists and performers and local vendors if
                desired. It also helps notify surrounding areas so that people
                are prepared for traffic and other disruptions.
              </p>

              <h4>Agencies Involved</h4>

              <p>Department of Cultural Affairs (DCLA)</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                The Creators Collective, Thrive Collective, Free Arts NYC, and
                others.
              </p>
            </section>

            <section id="press-credentialing">
              <h3>Press Credentialing</h3>

              <p>
                The NYPD is currently tasked with determining who is and who is
                not a credentialed member of the press. This can have a
                significant impact on reporting integrity – to gain access to
                press corps benefits, journalists are incentivized to avoid
                angering the NYPD. The NYPD press gatekeeping function is
                inherently antidemocratic, because it allows the NYPD to prevent
                press it may disagree with from receiving credentials needed to
                cover issues of public importance – and those issues may include
                police-involved matters. This is a clear conflict of interest.
              </p>

              <p>
                This past summer demonstrated the issue – the NYPD assaulted
                local journalists reporting on a demonstration and responded to
                the journalist’s complaints by saying that because the NYPD had
                not credentialed the journalists as press members, the
                complaints were invalid. The press is a fundamental part of our
                democracy, and a free press is prerequisite to functioning
                democracy. All press credentialing should be handled by the
                Department of Citywide Administrative Services commissioner, and
                include input from local community stakeholder organizations,
                local media outlets, representatives for new or emerging media
                outlets, and journalist labor unions.
              </p>

              <p>
                Example of Use: A newly-founded publication applies for press
                credentials in order to cover a large protest. The requirements
                for receiving credentials are clear, only as restrictive as
                necessary and public. The publication meets all requirements and
                receives credentials in a timely manner.
              </p>

              <h4>Agencies Involved</h4>

              <p>Department of Citywide Administrative Services (DCAS)</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                Reporters Committee for Freedom of the Press, local journalists,
                and others.
              </p>
            </section>

            <section id="privacy-protection">
              <h3>Privacy Protection Commission</h3>

              <p>
                Privacy rights violations are nuanced and require individualized
                responses. Police responses to privacy rights violations can
                further violate privacy and exacerbate trauma. People who feel
                their privacy has been violated could have a safe space to turn
                to try and resolve the situation in a way that they feel in
                control and protected, and we have to develop proactive
                approaches and accountability to digital privacy protection.
              </p>

              <p>
                A Privacy Protection Commission could work with a range of
                experts to address the various privacy violations, including
                gender violence through privacy violations, that are only
                becoming more problematic as our technology advances. Living in
                a city must not mean that our expectation of and right to
                privacy turn into a footnote in history.
              </p>

              <p>
                The privacy rights enjoyed by earlier generations could exist
                for the Internet generations. Systems that track people based on
                DNA or facial recognition or other pattern identification
                software effectively eliminate personal privacy. Our city should
                defund these systems and instead fund programming that protects
                privacy rights and proactively envisions ways to safeguard
                personal privacy into the new century.
              </p>

              <p>
                Example of Use: A person who has been filmed without their
                consent turns to the privacy protection commission, which
                provides the civil legal support necessary to help the person
                get their unauthorized image removed from various sites and the
                original footage confiscated/destroyed.
              </p>

              <p>
                Example of Use: A person’s computer is hacked, including
                non-consensual removal of private photographs. The person can
                turn to the Privacy Protection Commission to engage in a rapid,
                survivor-centered investigation focused on accountability,
                prevention of dissemination and preventative measures. The
                person can also receive resources to restore their confidence in
                their technology such as a new computer or privacy protection
                training.
              </p>

              <h4>Agencies Involved</h4>

              <p>
                Department of Information Technology & Telecommunications
                (DOITT), new agency
              </p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>
                American Civil Liberties Union (ACLU), Electronic Privacy
                Information Center (EPIC), and others.
              </p>
            </section>

            <section id="public-order">
              <h3>Civil Public Order and Property Protection Team</h3>

              <p>
                People often have concerns related to simple property-related
                issues or public disorder. Police involvement can unnecessarily
                lead to arrest or incarceration. The Civil Public Order and
                Property Protection service could include teams of people to
                take insurance reports and give advice on property protection
                measures and a robust victim's compensation fund. It would
                collaborate with other city service providers to develop
                targeted strategies to address the things that drive property
                crime including drug addiction and juvenile poverty.
              </p>

              <p>
                Example of Use: A shop is vandalized in the middle of the night.
                The owner/manager reports the vandalism to the Commission. They
                are compensated for repairs and given advice on additional
                property protection measures they can take.
              </p>

              <h4>Agencies Involved</h4>

              <p>New agency</p>

              <h4>Examples of Community Organizations Doing This Work</h4>

              <p>Restorative Justice Initiative, and others.</p>
            </section>
          </section>

          <section id="acknowledgements">
            <h2>Acknowledgements and Next Steps</h2>

            <p>
              Our campaign is working alongside community partners to build a
              coalition of candidates and elected officials to implement a new
              vision of public safety. At the beginning of 2022, a
              multi-committee initiative at the City Council, which will include
              community stakeholder organizations, will establish the budgetary
              needs for the agencies and groups that will replace the NYPD. This
              initiative will determine the best way to bring this plan to life.
            </p>

            <p>
              We are committed to ongoing engagement. This is a living plan that
              will continue to be informed by communities and people directly
              impacted by systems of oppression. We will host town halls and
              ongoing dialogue with our neighbors and neighborhoods across the
              city to ensure participatory co-governance as we build and
              implement our new vision for public safety. We will update our
              plans to reflect community feedback.
            </p>

            <p>
              The Cabán for Council Campaign would like to thank all the
              community organizations involved in contributing to this plan
              directly or by already doing the work this proposal calls for. We
              would like to thank Alana Sivin, Louis Cholden-Brown, Stephanie
              Silkowski, Kumar Rao, Paulette Soltani, Stanley Fritz, Joo-Hyun
              Kang, Kesi Foster, Janos Marton, Sarita Daftary, Bob Gangi, Luke
              Messina, Alex Liao, Katelin Penner, Aaron Narraph Fernando, Rapi
              Castillo, Stacy Magallon, Oren Gur, Jack Frésquez, Kate Walls,
              Peter Martin, Nick Smith, Veronica Aveis, Micah Herskind, Jonathan
              Hiles and Katherine Demby for their feedback, research and other
              support, Professors Anna Roberts, Steve Zeidman, Monica Bell, Leo
              Beletsky, James Forman and Alex Vitale for their guidance, wisdom
              and thoughtful insight, and Alon Gur for writing, researching, and
              partnering with our allies throughout the process.
            </p>
          </section>
        </div>

        <nav className="tmp-public-safety-toc">
          <ol>
            <li>
              <a href="#quotes">What Folks Are Saying</a>
            </li>
            <li>
              <a href="#summary">Summary</a>
            </li>
            <li>
              <a href="#introduction">Introduction</a>
            </li>
            <li>
              <a href="#community-safety-centers">Community Safety Centers</a>
            </li>
            <ol>
              <li>
                <a href="#family-supports">Family Supports</a>
              </li>
              <li>
                <a href="#violence-prevention">Violence Prevention Services</a>
              </li>
              <li>
                <a href="#mediation">Community Mediation Centers</a>
              </li>
              <li>
                <a href="#violence-mediation-centers">
                  Violence Mediation Centers and Community-based Alternatives to
                  Incarceration
                </a>
              </li>
              <li>
                <a href="#racism-response-fund">
                  Racism, Hate and Violence Response Funds
                </a>
              </li>
              <li>
                <a href="#non-police-teams">
                  Non-Police Crisis Intervention Teams
                </a>
              </li>
            </ol>
            <li>
              <a href="#integrated-service-facilities">
                Integrated Service Facilities
              </a>
            </li>
            <ol>
              <li>
                <a href="#housing-services">Housing Support Services</a>
              </li>
              <li>
                <a href="#safe-respite">
                  Safe Respite Homelessness Mitigation Programs
                </a>
              </li>
              <li>
                <a href="#substance-use">Substance Use Treatment Programs</a>
              </li>
              <li>
                <a href="#overdose">
                  Overdose Prevention and Safe Consumption Services
                </a>
              </li>
              <li>
                <a href="#legal-support">Legal Support Clinics</a>
              </li>
            </ol>
            <li>
              <a href="#school-support">
                School-Based Support Instead of Policing
              </a>
            </li>
            <ol>
              <li>
                <a href="#counselors">Counselors and Medical Staff Funding</a>
              </li>
              <li>
                <a href="#targeted-strats">
                  Individualized Targeted Strategies
                </a>
              </li>
              <li>
                <a href="#youth-restorative">
                  Youth Restorative Justice Programs
                </a>
              </li>
              <li>
                <a href="#wraparound">Wraparound Services</a>
              </li>
            </ol>
            <li>
              <a href="#traffic">Non-Police Traffic Safety</a>
            </li>
            <ol>
              <li>
                <a href="#traffic-responders">Non-Police Traffic Responders</a>
              </li>
              <li>
                <a href="#driver-safety">
                  Driver Safety Education and Outreach
                </a>
              </li>
              <li>
                <a href="#automated-traffic">
                  Automated Traffic Safety Systems
                </a>
              </li>
              <li>
                <a href="#data-traffic">Data-Driven Traffic Policy</a>
              </li>
            </ol>
            <li>
              <a href="#mental-health">Mental Health Services and Responders</a>
            </li>
            <ol>
              <li>
                <a href="#community-mental-health">
                  Community Mental Health Centers
                </a>
              </li>
              <li>
                <a href="#health-corps">Healthcare Responder Corps</a>
              </li>
            </ol>
            <li>
              <a href="#civil-corps">Civil Life Corps</a>
            </li>
            <ol>
              <li>
                <a href="#civil-safety">Civil Safety Corps</a>
              </li>
              <li>
                <a href="#democracy-corps">
                  Democracy Outreach and Protection Corps
                </a>
              </li>
              <li>
                <a href="#civil-engineer-corps">Civil Engineer Corps</a>
              </li>
              <li>
                <a href="#civil-equity">
                  Civil Equity Research and Data Analysis Transparency
                  Commission
                </a>
              </li>
              <li>
                <a href="#conservation-corps">Conservation Corps</a>
              </li>
              <li>
                <a href="#non-citizen-corps">
                  Non-Citizen Safety and Outreach Corps
                </a>
              </li>
              <li>
                <a href="#arts-corps">Arts Corps</a>
              </li>
              <li>
                <a href="#press-credentialing">Press Credentialing</a>
              </li>
              <li>
                <a href="#privacy-protection">Privacy Protection Commission</a>
              </li>
              <li>
                <a href="#public-order">
                  Civil Public Order and Property Protection Team
                </a>
              </li>
            </ol>
            <li>
              <a href="#acknowledgements">Acknowledgements and Next Steps</a>
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <Footer className="mt-5" />
  </main>
)

export default PublicSafetyPage
