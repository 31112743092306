import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo-primary.svg'

import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Button,
  ButtonGroup,
} from 'react-bootstrap'

const path = typeof window !== 'undefined' ? window.location.pathname : ''

const Header = (props) => (
  <div className="position-absolute top right w-100 z-toppest">
    <Navbar
      expand="xl"
      className={`p-0 ${props.className} justify-content-between`}
    >
      <Navbar.Brand href="/" className="ml-2">
        <img src={logo} />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        className="mr-2"
        style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
      />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end align-items-end"
      >
        <Nav className="p-2">
          <div className="text-right d-flex flex-row justify-content-center">
            <Nav.Link
              href="/about"
              className="color-base1 font-sans-serif font-weight-bold p-2 mr-lg-3"
            >
              About
            </Nav.Link>
            <Nav.Link
              href="/issues"
              className="color-base1 font-sans-serif font-weight-bold p-2 mr-lg-3"
            >
              Issues
            </Nav.Link>
            <Nav.Link
              href="/public-safety"
              className="color-base1 font-sans-serif font-weight-bold p-2 mr-lg-3"
            >
              Public Safety
            </Nav.Link>
            <Nav.Link
              href="/greennewdeal"
              className="color-base1 font-sans-serif font-weight-bold p-2 mr-lg-3"
            >
              Green New Deal
            </Nav.Link>
            <div style={{ marginRight: '8px' }}>
              <Nav.Link
                href="https://contribute.nycvotes.org/campaigns/tiffanycaban/contributions/new"
                className="d-inline-block font-sans-serif font-weight-bold rounded px-3 py-2 color-white bg-base2"
              >
                Donate
              </Nav.Link>
            </div>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </div>
)

export default Header

/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
    <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
    <NavDropdown.Divider />
    <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
  </NavDropdown>
  */
