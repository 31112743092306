import React from 'react'
import { Link, graphql } from 'gatsby'

import PageLayout from '../layout/page'
import MetaSEO from '../components/MetaSEO'
import PublicSafety from '../layout/PublicSafetyPage';
import flagImage from '../img/flagImage.png';





class PublicSafetyPage extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  formLoaded() {
  }

  render() {

    return (

      <PageLayout showBigCircle={false}>
        <MetaSEO
        title="A New Vision of Public Safety"
        description="Safe and healthy communities require divesting from police and creating new health-based first response systems. The true source of public safety is a robust public health infrastructure that promotes individual and family stability."
        keywords={[`tiffany cabán`, `tiffany caban`, `caban for queens`, `caban`, `cabán`, `queens district attorney`, `district attorney`]}
        url="https://www.cabanforqueens.com/public-safety"
        image= {flagImage}
        />
        <PublicSafety {...{...this.props.data.page}} />
      </PageLayout>
    );

  }

}

// <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
//   <Image />
// </div>
export default PublicSafetyPage;

export const PublicSafetyQuery = graphql`
  query publicSafetyPageQuery {
    page: markdownRemark (frontmatter: { uniq: {eq: "publicSafety" }}) {
      html
      frontmatter {
        title
        subtitle
        spiel
      }
    }
  }

`;
